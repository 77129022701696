import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "sidebar p-0 sidemenu-border",
  style: { width: 'inherit', paddingTop: '3.25rem !important' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBarLink = _resolveComponent("SideBarLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_SideBarLink, {
        key: item.label,
        menu: item,
        onClick: ($event: any) => (_ctx.changeToolBarTitle(item))
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("span", { class: "font-medium ml-2" }, _toDisplayString(item.label), 513), [
            [_vShow, !_ctx.sidemenu.collapsed]
          ])
        ]),
        _: 2
      }, 1032, ["menu", "onClick"]))
    }), 128))
  ]))
}