import { FdictResponse } from "@/types/fdict";

export const FDICT_DEFAULT: FdictResponse = {
  total_records_found: "3",
  BlockSize: "50",
  fdict_items: [
    {
      file_name: "SO",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "SO.ID",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "1",
          conv: "D2-",
          dict_name: "DATE",
          just: "R",
          index: "N",
          required: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "2",
          conv: "MCU",
          dict_name: "SOLD.TO",
          just: "L",
          index: "Y",
          required: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "3",
          conv: "D2-",
          dict_name: "BOOK.DATE",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "4",
          conv: "MCU",
          dict_name: "BILL.TO",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "5",
          dict_name: "SHIP.ADDRESS",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "6",
          dict_name: "CONTACT",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "PHONE",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "8",
          dict_name: "PO.NUMBER",
          just: "L",
          index: "Y",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "9",
          dict_name: "SHIP.VIA",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "REP",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "11",
          conv: "MD2",
          dict_name: "ORDER.PCT",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "HOLD",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "13",
          conv: "D2-",
          dict_name: "HOLD.DATE",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "14",
          dict_name: "STATUS",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "15",
          conv: "MCU",
          dict_name: "TERMS.CODE",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "16",
          dict_name: "FOB",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "17",
          dict_name: "CUST.CODE",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "18",
          conv: "MD4",
          dict_name: "TAX.RATE",
          just: "R",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "RESALE.NUMBER",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "20",
          conv: "D2-",
          dict_name: "CLOSE.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "LAST.SHIP.NO",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "22",
          conv: "D2-",
          dict_name: "LAST.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "NOTES",
          just: "T",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "24",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "STAMP.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "26",
          dict_name: "LIS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "27",
          conv: "MCU",
          dict_name: "LI.PARTS",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "28",
          dict_name: "LI.DESCS",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "LI.REVS",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "30",
          conv: "D2-",
          dict_name: "LI.SCHED.DATES",
          just: "R",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "31",
          conv: "MD2",
          dict_name: "LI.SCHED.QTYS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MD2",
          dict_name: "LI.ORDER.QTYS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "33",
          conv: "MD2",
          dict_name: "LI.OPEN.QTYS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "34",
          conv: "MD4",
          dict_name: "LI.PRICES",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "35",
          conv: "MD2",
          dict_name: "LI.DISCS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "36",
          conv: "MD4",
          dict_name: "LI.DISC.AMTS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "37",
          conv: "MCU",
          dict_name: "LI.FG.LOCS",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "LI.NOTES",
          just: "T",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "39",
          dict_name: "LI.TAXABLES",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "40",
          conv: "MCU",
          dict_name: "LI.REPS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "41",
          conv: "MD2",
          dict_name: "LI.COMMS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "42",
          dict_name: "LI.HOLDS",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "43",
          conv: "D2-",
          dict_name: "LI.HOLD.DATES",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "LI.SHIP.NOS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "45",
          conv: "D2-",
          dict_name: "LI.SHIP.DATES",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "46",
          conv: "MD2",
          dict_name: "LI.SHIP.QTYS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "47",
          conv: "MD2",
          dict_name: "LI.TOTAL.SHIP",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "48",
          conv: "MCU",
          dict_name: "LI.SALES.ACCTS",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "LAST.RMA.NUMBER",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "50",
          conv: "D2-",
          dict_name: "LAST.RMA.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "51",
          dict_name: "TYPE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "52",
          dict_name: "FREIGHT.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "53",
          conv: "MCU",
          dict_name: "LI.QUOTE.NO",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "COMMITED",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "55",
          conv: "MCU",
          dict_name: "LI.WOS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "56",
          conv: "D2-",
          dict_name: "PRINT.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "57",
          conv: "D2-",
          dict_name: "REQUIRED",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "58",
          dict_name: "PARTIALS.OK",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "59",
          conv: "MCU",
          dict_name: "TAX.CODES",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "60",
          conv: "MCU",
          dict_name: "CG.LOC",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "61",
          conv: "MCU",
          dict_name: "QUOTE.NO",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "62",
          dict_name: "LI.QUOTE.LI",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "63",
          dict_name: "CUST.PART",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "PLAN.GROUP",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "65",
          dict_name: "EXT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "66",
          conv: "MCU",
          dict_name: "CO.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "67",
          dict_name: "SHIP.CITY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "SHIP.STATE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "69",
          dict_name: "SHIP.ZIP",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "70",
          dict_name: "SHIP.GEOCODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "71",
          conv: "MCU",
          dict_name: "PROJECT.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "TASK.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "SHIP.SEQ",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "101",
          conv: "MCU",
          dict_name: "VENDOR",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "102",
          conv: "MD4",
          dict_name: "PO.COST",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "103",
          conv: "MCU",
          dict_name: "PO.ID",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "104",
          dict_name: "PO.LI",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "DROP.SHIP",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "SHIP.NAME",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "SHIP.COUNTRY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "SHIP.ATTN",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "SHIP.COMM",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "SHIP.PHONE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "111",
          conv: "MD0",
          dict_name: "PRICE.PER",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "112",
          dict_name: "PENDING.SHIP",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "CREDITCARD.NO",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "CREDITCARD.TYPE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "115",
          dict_name: "CARDHOLDER.NAME",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "116",
          dict_name: "CREDITCARD.EXP",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "117",
          dict_name: "CREDITCARD.CVV",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "118",
          dict_name: "CREDITCARD.TRAN",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "119",
          conv: "MD2",
          dict_name: "CREDITCARD.AMT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "120",
          dict_name: "CREDITCARD.POST",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "121",
          conv: "MD2",
          dict_name: "CC.CONV.FEE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "SHIP.EMAIL",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "SHIP.FRT.ACCT",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "124",
          dict_name: "CONTACT.EMAIL",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "125",
          dict_name: "ORDER.SOURCE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD2",
          dict_name: "FREIGHT.AMOUNT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "FREIGHT.TYPE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "128",
          dict_name: "FREIGHT.LOCK",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "129",
          dict_name: "WRAP.DESC",
          just: "T",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "130",
          conv: "MCU",
          dict_name: "COUPON.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "131",
          dict_name: "COUPON.TYPE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "132",
          conv: "MD2",
          dict_name: "COUP.DISC.AMT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "133",
          conv: "MD2",
          dict_name: "COUP.DISC.PCT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "134",
          conv: "MD2",
          dict_name: "COUP.MAX.DISC",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "135",
          conv: "MD2",
          dict_name: "COUP.MIN.ORD",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "136",
          dict_name: "COUP.LI",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "137",
          conv: "MD2",
          dict_name: "COUP.LI.DISC",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "138",
          conv: "MD2",
          dict_name: "COUP.SHIP.AMT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "EDI.ORDER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "140",
          conv: "MCU",
          dict_name: "CONTACT.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "141",
          conv: "MCU",
          dict_name: "CM.ID",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "142",
          conv: "MD2",
          dict_name: "CM.AMOUNT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "143",
          dict_name: "CC.ADDR",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "144",
          dict_name: "CC.CITY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "145",
          dict_name: "CC.STATE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "146",
          dict_name: "CC.ZIP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "147",
          dict_name: "CC.COUNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "148",
          dict_name: "CREDITCARD.ERR",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "151",
          dict_name: "CURRENCY.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "152",
          conv: "MD4",
          dict_name: "EXCHANGE.RATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "153",
          dict_name: "SAT.DELIVERY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "154",
          dict_name: "SHIPPER.ACCOUNT",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "155",
          dict_name: "ACCT.RELEASE.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "156",
          dict_name: "END.USER.CNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "157",
          dict_name: "END.USER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "158",
          dict_name: "END.USER.STMT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "159",
          dict_name: "LI.END.USE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "160",
          dict_name: "EXPORT.LIST",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "161",
          dict_name: "EXPORT.LIST.CHK",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "162",
          dict_name: "FIRST.SHIP.ONLY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "163",
          dict_name: "FRT.SERV.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "164",
          dict_name: "PKG.TYPE.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "165",
          dict_name: "PKG.WEIGHT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "166",
          dict_name: "PKG.WEIGHT.UOM",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "167",
          dict_name: "PKG.LENGTH",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "168",
          dict_name: "PKG.WIDTH",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "169",
          dict_name: "PKG.HEIGHT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "170",
          dict_name: "PKG.DIM.UOM",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "171",
          conv: "MD2",
          dict_name: "PKG.VALUE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "172",
          conv: "MD2",
          dict_name: "FRT.MARKUP.AMT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "173",
          dict_name: "CREDIT.CARD.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "174",
          conv: "MD4",
          dict_name: "TAX.CODE.RATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "175",
          dict_name: "TAX.FREIGHT",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "176",
          dict_name: "PAYMENT.PLAN",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "177",
          dict_name: "PREPAY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "178",
          conv: "MD2",
          dict_name: "TAX.AMOUNT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "179",
          dict_name: "MIVA.LI.NO",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "201",
          conv: "MCU",
          dict_name: "LOT.NUMBER",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "202",
          dict_name: "SIGNATURE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "204",
          conv: "D2-",
          dict_name: "SIGNATURE.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "205",
          conv: "MTS",
          dict_name: "SIGNATURE.TIME",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "206",
          dict_name: "SIGNATURE.BY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "207",
          dict_name: "SIGNATURE.TYPE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "208",
          dict_name: "SHOPIFY.ID",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "209",
          dict_name: "PRICE.MOD.CODE",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "210",
          dict_name: "POS.ORDER.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "211",
          conv: "MCU",
          dict_name: "SERIAL.ID",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "212",
          dict_name: "RELEASE.CODE",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
    {
      file_name: "CUST",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "CUST.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "1",
          dict_name: "NAME",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "2",
          dict_name: "ADDRESS",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "3",
          dict_name: "PHONE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "5",
          conv: "MCU",
          dict_name: "BILL.TO",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "6",
          conv: "MD2",
          dict_name: "SALES.PERCENT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "SHIP.VIA",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "8",
          conv: "MCU",
          dict_name: "REP",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "9",
          conv: "MD2",
          dict_name: "ORDER.PCT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "10",
          conv: "MCU",
          dict_name: "TERMS",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "11",
          conv: "MD0",
          dict_name: "CREDIT.LIMIT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "13",
          dict_name: "RESALE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "14",
          conv: "MD2",
          dict_name: "DISC",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "15",
          dict_name: "NOTES",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "16",
          conv: "MD2",
          dict_name: "AR.BAL",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "17",
          conv: "MD2",
          dict_name: "OPEN.ORDER.BAL",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "18",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "STAMP.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "20",
          dict_name: "CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "FAX",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "22",
          dict_name: "CREDIT.HOLD",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "24",
          dict_name: "SHIP.SEQ",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "SHIP.TO",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "26",
          conv: "MCU",
          dict_name: "TAX.CODES",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "27",
          dict_name: "SORT.CITY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "28",
          dict_name: "SORT.STATE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "SORT.ZIP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "30",
          dict_name: "PARTIALS.OK",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "31",
          dict_name: "SORT.COUNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MCU",
          dict_name: "SHIP.TO.TAX",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "33",
          dict_name: "NAME.XREF",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "34",
          conv: "MCU",
          dict_name: "CONTACT.ID",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "35",
          dict_name: "CONTACT.TYPE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "CAMPAIGN",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "39",
          dict_name: "MRK.OPT.OUT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "40",
          dict_name: "GROUP.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "41",
          conv: "D2-",
          dict_name: "DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "43",
          dict_name: "GEOCODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "SHIP.CITY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "45",
          dict_name: "SHIP.STATE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "46",
          dict_name: "SHIP.ZIP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "47",
          dict_name: "SHIP.GEOCODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "48",
          dict_name: "CONF.NOTES",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "PHONE.XREF",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "50",
          dict_name: "PAST.DUE.DAYS",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "51",
          conv: "MCU",
          dict_name: "CONTRACT.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "52",
          conv: "MCU",
          dict_name: "PROSPECT.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "53",
          conv: "MCU",
          dict_name: "ROUTE.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "STORE.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "55",
          dict_name: "SHIP.NAME",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "56",
          dict_name: "SHIP.COUNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "57",
          dict_name: "SHIP.ATTN",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "58",
          dict_name: "SHIP.COMM",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "59",
          dict_name: "SHIP.PHONE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "60",
          dict_name: "SHIP.FAX",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "61",
          dict_name: "CREDIT.CARD.NO",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "62",
          dict_name: "CREDIT.CARD.EXP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "63",
          dict_name: "CREDIT.CARD.SEC",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "CREDIT.CARD.ID",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "BANK.NO",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "CARDHOLDER.NAME",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "74",
          dict_name: "REBATE.TYPE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "75",
          conv: "MD2",
          dict_name: "REBATE.PCT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "76",
          dict_name: "REBATE.FREQ",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "77",
          conv: "MCU",
          dict_name: "REBATE.ACCT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "78",
          conv: "MD2",
          dict_name: "FROM.SALES.AMT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "79",
          conv: "MD2",
          dict_name: "TO.SALES.AMT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "101",
          dict_name: "SALES.FREQUENCY",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "102",
          conv: "MCU",
          dict_name: "SALES.ACCT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "103",
          dict_name: "SHIP.EMAIL",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "SHIP.ACCT.NO",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "SHIP.CARRIER",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "CARRIER",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "FRT.ACCOUNT",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "109",
          dict_name: "THIRD.PARTY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "TP.STREET",
          just: "T",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "111",
          dict_name: "TP.CITY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "112",
          dict_name: "TP.STATE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "TP.ZIP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "TP.COUNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "115",
          dict_name: "FREIGHT.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "116",
          dict_name: "WEB.CUST",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "117",
          dict_name: "QUICKLIST.NAME",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "118",
          conv: "MCU",
          dict_name: "QUICKLIST.PART",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "119",
          conv: "D2-",
          dict_name: "QUICKLIST.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "120",
          conv: "MD2",
          dict_name: "QUICKLIST.QTY",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "121",
          conv: "MD4",
          dict_name: "QUICKLIST.PRICE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "122",
          conv: "MD4",
          dict_name: "QUICKLIST.DISC",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "123",
          conv: "D2-",
          dict_name: "INACTIVE.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "130",
          dict_name: "CC.ADDR",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "131",
          dict_name: "CC.CITY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "132",
          dict_name: "CC.STATE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "133",
          dict_name: "CC.ZIP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "134",
          dict_name: "CC.COUNTRY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "137",
          dict_name: "EXPORTER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "138",
          dict_name: "END.USER.CNTRY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "WEBSITE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "140",
          dict_name: "ECOMMERCE.LEVEL",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "141",
          dict_name: "CONTACT.PORTAL",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "142",
          dict_name: "CURRENCY.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "143",
          dict_name: "USE.CURRENCY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "144",
          conv: "D2-",
          dict_name: "AVATAX.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "145",
          conv: "MTS",
          dict_name: "AVATAX.TIME",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "146",
          dict_name: "BANK.ROUTING",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "147",
          dict_name: "BANK.ACCOUNT",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "148",
          dict_name: "ACCOUNT.TYPE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "149",
          conv: "MD2",
          dict_name: "RATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "150",
          dict_name: "TYPE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "151",
          dict_name: "BILL.RATE.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
    {
      file_name: "PARTS",
      field_no_items: [
        {
          field_no: "0",
          conv: "MCU",
          dict_name: "PART.NO",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "1",
          dict_name: "DESCRIPTION",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "2",
          dict_name: "TYPE",
          just: "L",
          required: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "3",
          dict_name: "UM",
          just: "L",
          required: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "4",
          dict_name: "SPECS",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "5",
          dict_name: "NOTES",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "6",
          dict_name: "MFG.NAME",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "7",
          dict_name: "MFG.PART",
          just: "L",
          index: "Y",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "8",
          dict_name: "REV",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "9",
          dict_name: "ABC.CODE",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "10",
          dict_name: "SAFETY.STOCK",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "11",
          dict_name: "ORDER.QTY",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "12",
          dict_name: "LEAD.TIME",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "13",
          conv: "MCU",
          dict_name: "PRODCON.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "14",
          dict_name: "FRACTIONS",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "15",
          dict_name: "STATUS",
          just: "L",
          required: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "16",
          dict_name: "MRP.PART",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "17",
          dict_name: "MIN.MAX.PART",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "18",
          dict_name: "PICKLIST.LABELS",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "19",
          dict_name: "MAX.STOCK",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "20",
          conv: "MCU",
          dict_name: "VENDOR",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "21",
          dict_name: "LOT.CONTROL",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "22",
          conv: "MD0",
          dict_name: "SHELF.LIFE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "23",
          dict_name: "BUYER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "24",
          dict_name: "PLANNER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "25",
          dict_name: "REV.HISTORY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "26",
          dict_name: "REV.DESC",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "27",
          conv: "MCU",
          dict_name: "REV.ECN",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "28",
          conv: "D2-",
          dict_name: "REV.DATE",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "29",
          dict_name: "DRAWING.NUMBER",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "30",
          dict_name: "BUY.UM",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "31",
          dict_name: "FACTOR",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "32",
          conv: "MD2",
          dict_name: "WEIGHT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "33",
          conv: "MCU",
          dict_name: "INVLOC",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "34",
          dict_name: "CONFIG.GROUP",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "35",
          dict_name: "CONFIG.OPTION",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "36",
          dict_name: "CONFIG.NOTES",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "37",
          dict_name: "CONFIG.DESC",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "38",
          dict_name: "CONFIG.ID",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "39",
          conv: "MCU",
          dict_name: "MRK.CODE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "41",
          dict_name: "MODEL",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "42",
          dict_name: "CATEGORY",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "43",
          dict_name: "LOW.LEVEL.CODE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "44",
          dict_name: "TPOP",
          just: "R",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "45",
          conv: "MCU",
          dict_name: "ROUTING",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "46",
          dict_name: "PEGGING",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "47",
          dict_name: "ORDER.POLICY",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "48",
          dict_name: "ORDER.MULTIPLE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "49",
          dict_name: "ORDER.MAXIMUM",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "50",
          conv: "MD2",
          dict_name: "SCRAP.FACTOR",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "52",
          dict_name: "MAKE.BUY",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "53",
          dict_name: "MPS.PART",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "54",
          dict_name: "MPS.LOW.LEVEL",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "55",
          dict_name: "PLAN.GROUP",
          just: "l",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "56",
          conv: "MCU",
          dict_name: "WIP.LOC",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "57",
          conv: "MCU",
          dict_name: "RI.LOC",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "58",
          conv: "D2-",
          dict_name: "STAMP.DATE",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "59",
          dict_name: "STAMP.USER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "60",
          dict_name: "TAXABLE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "61",
          dict_name: "DESC.WORDS",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "62",
          conv: "MCU",
          dict_name: "STOCK.LOC",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "63",
          conv: "MCU",
          dict_name: "LSTOCK.LOC",
          just: "L",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "64",
          dict_name: "UPC",
          just: "L",
          index: "Y",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "66",
          dict_name: "CREATE.WO",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "67",
          dict_name: "IMAGE.DESC",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "68",
          dict_name: "IMAGE.PATH",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "69",
          conv: "MD4",
          dict_name: "FET",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "70",
          dict_name: "WEB.CATEGORY",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "71",
          dict_name: "WEB.DESCRIPTION",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "72",
          dict_name: "WEB.INFORMATION",
          just: "T",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "73",
          dict_name: "WEB.IMAGE.PATH",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "74",
          conv: "MCU",
          dict_name: "CUST",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "75",
          dict_name: "CUST.PART",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "76",
          conv: "MCU",
          dict_name: "EQUIV.PART",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "77",
          dict_name: "SUPERCEDE.FLAG",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "78",
          dict_name: "SALES.HOLD",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "79",
          dict_name: "SERIAL.REQUIRED",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "101",
          dict_name: "BOM.UM",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "102",
          dict_name: "BOM.UM.FACTOR",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "103",
          dict_name: "NO.CAT.DISC",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "104",
          dict_name: "MRP.DECIMALS",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "105",
          dict_name: "COUNTRY.ORIGIN",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "106",
          dict_name: "MAKE.HOLD",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "107",
          dict_name: "BUY.HOLD",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "108",
          dict_name: "WRAP.DESC",
          just: "T",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "109",
          conv: "MCU",
          dict_name: "WEB.CUSTOMER",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "110",
          dict_name: "WEB.FEATURED",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "111",
          dict_name: "ROHS",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "112",
          conv: "D2-",
          dict_name: "DATE.CREATED",
          just: "R",
          index: "N",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "113",
          dict_name: "WEB.NO.BUY",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "114",
          dict_name: "WEB.PRICE.TEXT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "121",
          dict_name: "EXP.REGULATION",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "122",
          dict_name: "BIS.LICENSE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "123",
          dict_name: "HTS.SCHEDB",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "124",
          conv: "MD2",
          dict_name: "SHIP.LENGTH",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "125",
          conv: "MD2",
          dict_name: "SHIP.WIDTH",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "126",
          conv: "MD2",
          dict_name: "SHIP.HEIGHT",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "127",
          dict_name: "SHIP.SEPARATE",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "128",
          dict_name: "INSP.STEP",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "129",
          dict_name: "INSP.INSTRUCT",
          just: "T",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "130",
          conv: "MD2",
          dict_name: "SAMPLE.PCT",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "133",
          conv: "MD2",
          dict_name: "SHIP.QTY",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "134",
          dict_name: "CONFIG.CODE",
          just: "L",
          index: "Y",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "135",
          dict_name: "AVATAX.CODE",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "136",
          dict_name: "MIVA.PRODUCT.ID",
          just: "R",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "137",
          dict_name: "MIVA.IMAGES",
          just: "L",
          index: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "138",
          dict_name: "IMAGE.ID",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "139",
          dict_name: "IMAGE.DEFAULT",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "140",
          conv: "MCU",
          dict_name: "ALT.PART.NO",
          just: "L",
          index: "Y",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "141",
          dict_name: "BOM.DECIMALS",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "142",
          conv: "MD0",
          dict_name: "TPOP.DAYS",
          just: "R",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "143",
          conv: "MD4",
          dict_name: "PRODUCT.WEIGHT",
          just: "R",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "144",
          conv: "MCU",
          dict_name: "ALT.ROUTING",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "145",
          dict_name: "NO.NET.PRICE",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "146",
          conv: "MCU",
          dict_name: "RIDEF.ID",
          just: "L",
          desc_items: [],
          json_name: "",
        },
        {
          field_no: "147",
          conv: "MCU",
          dict_name: "AUTO.RI",
          just: "L",
          required: "N",
          desc_items: [],
          json_name: "",
        },
        {
          required: "N",
          field_no: "",
          dict_name: "",
          desc_items: [],
          json_name: "",
        },
      ],
      correl_field_no_items: [],
    },
  ],
};
